import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./slick.css";
import "./slick-theme.css";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Shop from "./pages/Shop";
import ContactLenses from "./pages/shop/ContactLense";
import Eyeglasses from "./pages/shop/Eyeglasses";
import Kid from "./pages/shop/Kids";
import Men from "./pages/shop/Mens";
import PremiumGlasses from "./pages/shop/PremiumGlasses";
import Sunglasses from "./pages/shop/Sunglasses";
import Women from "./pages/shop/Womens";
import Service from "./pages/Service";
import Blog from "./pages/blog/blog"
import EyeCareKids from "./pages/blog/EyeCareKids";
import EyeglassesForOffice from "./pages/blog/EyeglassesForOffice";
import GuideToChoosing from "./pages/blog/GuideToChoosing";
import PowerOfSunglasses from "./pages/blog/PowerOfSunglasses";
import Review from "./pages/Review";
import Privacy from "./pages/privacy";
import Term from "./pages/term";
import Gallery from "./components/Gallery";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/review" element={<Review />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/term-condition" element={<Term />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/contact-lenses" element={<ContactLenses />} />
        <Route path="/eyeglasses" element={<Eyeglasses />} />
        <Route path="/Kid" element={<Kid />} />
        <Route path="/Men" element={<Men />} />
        <Route path="/premium-glasses" element={<PremiumGlasses />} />
        <Route path="/sunglasses" element={<Sunglasses />} />
        <Route path="/women" element={<Women />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/eye-care-for-kids/" element={<EyeCareKids />} />
        <Route path="/blog/eyeglasses-for-office/" element={<EyeglassesForOffice />} />
        <Route path="/blog/guide-to-choosing/" element={<GuideToChoosing />} />
        <Route path="/blog/power-of-sunglasses/" element={<PowerOfSunglasses />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
